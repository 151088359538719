import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <span>let's play some ai</span>
  );
}

export default App;
